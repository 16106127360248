<template>
  <div class="sf-three-col">
    <div class="tc-header">
      <slot name="colTcHeader"></slot>
    </div>
    <colTcLeft class="tc-left"><slot name="colTcLeft"></slot></colTcLeft>

    <div class="tc-center">
      <slot name="colTcCenter"></slot>
    </div>

    <div class="tc-right"><slot name="colTcRight"></slot></div>
  </div>
</template>
