
import { defineComponent } from "vue";
import ECSIAHeader from "@/components/ECSIA/ecsiaHeader.vue";

export default defineComponent({
  data() {
    return {
      technology: {
        type: String,
        title: "Our<br />Proprietary<br />Technology",
        paragraph:
          "Going beyond hydroponics or aquaponics. ECSIA<sup>&reg;</sup> is Commercial Regenerative Agricultural (CRA). ECSIA<sup>&reg;</sup> produces the most nutrient-dense and enzyme-rich vegetables, fruits, and herbs available in the market today.",
        image: "img4_small.jpg",
      },
    };
  },
  components: { ECSIAHeader },
});
